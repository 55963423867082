/*
 * Name: Home Hero
 * Desc: Home page hero
 *
*/

@import "@styles/variables.scss";

.PopularParties {
  background: $hopp-light-blue;
  position: relative;
  .section {
    padding-bottom: $section-m - $base-4;
    @media screen and (min-width: $netbook) {
      padding-bottom: $section-d - $base-6;
    }
  }

  .header {
    position: relative;
    text-align: center;
    margin-bottom: 0;

    p {
      margin: 0;
    }
    h2,
    p {
      width: 65%;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }
    .text {
      position: relative;
    }
  }
  .grid {
    position: relative;
    overflow: hidden;
  }
  .nav {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    .navWrapper {
      position: relative;
    }
    .next,
    .prev {
      pointer-events: auto;
      position: absolute;
      top: -15px;
      border: none;
      background: none;
      outline: none;
      width: 25px;
      height: 25px;
      padding: 15px;
      background-color: $light-grey;
      border-radius: 100%;
      background-position: center center;
      background-size: 7px 14px;
      background-repeat: no-repeat;
      cursor: pointer;
      transition: all 300ms;
      box-shadow: $elevation-light;
      &.disabled {
        opacity: 0.5;
        cursor: default;
      }
      &:hover {
        transform: translateY(-1px);
        box-shadow: $elevation-light-hover;
      }
    }
    .next {
      right: 0;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23131730'%2F%3E%3C%2Fsvg%3E");
    }
    .prev {
      left: 0;
      margin-right: $base-2;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23131730'%2F%3E%3C%2Fsvg%3E");
    }
  }

  .card {
    transition: all 500ms ease;
    padding-bottom: $base-6;
    padding-top: $base-5;
    &:hover {
      transform: translateY(-2px);
    }

    .link {
      display: block;
      position: relative;
    }

    .image {
      position: relative;
      img {
        transition: all 200ms ease;
        display: block;
      }
      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: $soft-grey;
        mix-blend-mode: multiply;
        opacity: 1;
      }
    }
    .text {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: $base-2;
      right: $base-2;
      color: $light-text;
      text-align: center;

      h3 {
        justify-content: center;
        font-weight: 500;
        font-size: 21px;
        letter-spacing: 0.5px;
        margin: 0;
        @media screen and (min-width: $netbook) {
          font-size: 20px;
        }
      }
    }
  }
}
