/*
 * Name: Product Archive Card
 * Desc:
 *
*/

@import "@styles/variables.scss";

.ProductArchiveCard {
  position: relative;
  height: 400px;
  padding: 0;
  transition: all 400ms ease-in-out;
  box-shadow: $elevation-4;
  @media screen and (min-width: $laptop) {
    height: 500px;
  }
  .image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &:after {
      position: absolute;
      content: "";
      background: rgba(0, 0, 0, 0.4);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .picture {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 400ms ease-in-out, opacity 500ms ease 500ms !important; //overwrite dato css
    }
  }

  .title {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $light-text;
    flex-direction: column;
    margin-bottom: 0;
    text-align: center;
    padding: $base-2;

    h2 {
      font-size: 35px;
      max-width: 350px;
      margin: auto;
    }

    span {
      display: block;
      text-transform: lowercase;
      font-style: italic;
      margin-bottom: $base-1;
      font-size: 20px;
    }

    @media screen and (min-width: $tablet) {
      h2 {
        font-size: 30px;
      }
      span {
        font-size: 25px;
      }
    }
  }

  &:hover {
    opacity: 0.92;
    .picture {
      transform: scale(1.05);
    }
  }
}
