/*
 * Name: LatestPosts
 * page: home
 *
*/

@import "@styles/variables.scss";

.LatestPosts {
  background: $hopp-light-blue;
  h2 {
    text-align: center;
    margin-bottom: $base-4;
  }
}
.cta {
  margin: auto;
  margin-top: $base-5;
}
