/*
 * Name: Cards CTA
 * page: home
 *
*/

@import "@styles/variables.scss";

.CardsCTA {
  .grid {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: $base-3;
    @media screen and (min-width: $tablet) {
      grid-template-columns: 1fr 1fr;
      column-gap: $base-3;
      margin: auto;
    }
    .card {
      position: relative;
      transition: all 150ms ease;

      .image {
        border-radius: 10px;
        box-shadow: $elevation-4;
        transition: all 300ms ease;
      }

      &:hover {
        opacity: 0.95;
        transform: translateY(-2px);
        .image {
          box-shadow: $elevation-4-hover;
        }
      }
      &:active {
        opacity: 1;
        transform: translateY(2px);
      }
      .text {
        position: absolute;
        color: $light-text;
        bottom: 8%;
        left: 6%;
        h2 {
          text-transform: none;
          font-weight: 400;
          font-size: 22px;
          margin: 0 0 $base-1;
        }
        span {
          text-transform: uppercase;
          font-size: 13px;
          letter-spacing: 1px;
        }
        @media screen and (min-width: $laptop) {
          h2 {
            font-size: 30px;
            margin: 0 0 $base-1 + $base-s;
          }
          span {
            display: block;
            margin: 0 0 $base-1;
          }
        }
      }
    }
  }
}
