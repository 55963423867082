/*
 * Name: Blog
 * Desc:
 *
*/

@import '@styles/variables.scss';

.PostsList {
    h2 {
        text-align: center;
        margin-bottom: $base-3;
    }
    .grid {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: $base-2;
        row-gap: $base-4;
        @media screen and (min-width:$tablet) {
            grid-template-columns: 1fr 1fr;
            column-gap: $base-3;
        }
        @media screen and (min-width:$netbook) {
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: $base-4;
            row-gap: $base-4;
        }
    }
    &.thumbnails .grid {
        grid-template-columns: 1fr;
    }
}