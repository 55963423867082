/*
 * Name: Home Hero
 * Desc: Home page hero
 *
*/
@use "sass:math";

@import "@styles/variables.scss";

.HomeHero {
  position: relative;
  background: $dark-background;
  color: $light-text;

  display: grid;
  grid-template-columns: 1fr;
  align-items: center;

  @media (min-width: $tablet) {
    grid-template-columns: 1fr 1fr;
    min-height: 495px;
    height: calc(100vh - 70px);
    max-height: 800px;
  }

  .section {
    position: relative;
    width: 100%;
    height: 100%;

    .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($hopp-dark-blue, 0.5);
      }
    }
    .text {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      max-width: math.div($site-max-width, 2);

      .title {
        font-size: 40px;
        font-weight: 700;
        margin-bottom: 1rem;
      }
      .subtitle {
        font-size: 1.5rem;
        font-weight: 400;
        margin-bottom: 2rem;
      }
    }
    .textInner {
      padding: $base-7 0;
      max-width: 250px;

    }
    &:nth-child(1) {
      .text {
        justify-content: flex-start;
        margin-left: auto;
      }
      .textInner {
        text-align: left;
        padding-left: $base-5;
        padding-right: $base-3;
        align-self: flex-start;
      }
    }
    &:nth-child(2) {
      .text {
        justify-content: flex-end;
        margin-right: auto;
      }
      .textInner {
        text-align: right;
        padding-right: $base-5;
        padding-left: $base-3;
        align-self: flex-end;
        .title {
          max-width: 240px;
          margin-left: auto;
        }
        .button {
          margin-left: auto;
        }
      }
    }
  }
}
