/*
 * Name: PostBody
 * Desc:
 *
*/

@import "@styles/variables.scss";

.FeaturedSuppliers {
  // background: $hopp-light-blue;

  .header {
    text-align: center;
  }

  .title {
    color: $dark-text;
    text-transform: none;
    font-size: 30px;
    max-width: 800px;
    margin: auto;
    margin-bottom: $base-3;
    justify-content: center;
    @media screen and (min-width: $netbook) {
      font-size: 40px;
      margin-bottom: $base-5;
    }
    
  }

  .subtitle {
    // font-size: 23px;
    justify-content: center;
    color: $soft-grey-text;
    margin-bottom: $base-1;
  }
  .cta {
   
    margin: auto;
    margin-top: $base-3;
    @media screen and (min-width: $netbook) {
      margin-top: $base-5;
    }
  }

  .wrapper .wide {
    @media screen and (min-width: $laptop) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
  
}


