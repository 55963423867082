/*
 * Name: How Hopp Works
 * page: home
 *
*/

@import "@styles/variables.scss";

.HowHoppWorks {
  background: $body-background;
  text-align: center;
  // padding-bottom: $section-m;
  // @media screen and (min-width: $netbook) {
  //   padding-bottom: $section-d;
  // }

  h2 {
    margin-bottom: $base-1;
    @media screen and (min-width: $netbook) {
      margin-bottom: $base-5;
    }
  }
  .grid {
    @media screen and (min-width: $tablet) {
      display: flex;
      justify-content: space-between;
      column-gap: $base-3;
      row-gap: $base-3;
      width: 85%;
      max-width: 900px;
      margin-left: auto;
      margin-right: auto;
    }
    @media screen and (min-width: $tablet) {
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: $base-5;
    }
  }
  .feature {
    max-width: 200px;
    margin: auto;
    background: $body-background;
    outline: 0;
    border-radius: 3px;
    padding: $base-3;
    padding-bottom: 0;
    text-align: left;
    @media screen and (min-width: $netbook) {
      border: none;
      margin: 0;
      height: 100%;
      box-sizing: border-box;
      padding: 0;
    }

    @media screen and (min-width: 1500px) {
      h3 {
        font-size: 25px;
      }
    }

    span {
      display: block;
      margin: $base-2 0 $base-s;
    }

    h3 {
      margin: 0 0 $base-2;
      text-transform: none;
      font-size: 21px;
      font-weight: 400;
      justify-content: flex-start;
    }
    p {
      margin: 0;
      color: $soft-grey-text;
      color: $grey-text;
    }
    img {
      height: 120px;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    margin-top: $base-5;
  }
}
