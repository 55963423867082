/*
 * Name: Blog
 * Desc:
 *
*/

@import '@styles/variables.scss';

.PostCard {
    .image {
        box-shadow: $elevation-1;
        overflow: hidden;
        .img {
            transition: all .4s ease-in-out;
        }
    }

    .info {
        box-shadow: $elevation-1;
        position: relative;
        z-index: 1;
        background: $body-background;
        padding: $base-2;
        margin-top: -$base-3;
        margin-left: $base-2;
        margin-right: $base-2;
        text-align: center;
        h3 {
            color: $dark-text;
            text-transform: none;
            font-size: 16px;
            line-height: 1.5;
            @media screen and (min-width:$netbook) {
                font-size: 18px;
            }
        }
        span {
            color: $soft-grey;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 1px;
            text-transform: uppercase;
        }
    }

    &:hover {
        .img {     
          transform: scale(1.05);
        }
    }

    &.featured {
        @media screen and (min-width:$tablet) {
            grid-column: span 2;
            .inner {
                display: flex;
                align-items: center;
                .image {
                    width: 66.66%;
                }
                .info {
                    width: 33.33%;
                    margin:0;
                    margin-left: -$base-3;
                    padding: $base-2;
                    h3 {
                        font-size: 22px;
                    }
                }
            }
        }
        @media screen and (min-width:$netbook) {
            grid-column: span 3;
            .inner {
                .info {

                    margin-left: -$base-4;
                    padding: $base-5;
                    h3 {
                        font-size: 31px;
                    }
                }
            }
        }
    }
    &.thumbnails {
        .inner {
            display: grid;
            grid-template-columns: 56px 1fr ;
            column-gap: $base-2;
            align-items: flex-start;
        }
        .info {
            margin:0;
            padding: 0;
            text-align: left;
            box-shadow: none;
            h3 {
                margin-bottom: $base-s;
                font-size: 16px;
                font-weight: 500;
            }
        }
        .image {
            box-shadow: none;
            height: 60px;
        }
    }
}